export const BASIC_URL = process.env.NODE_ENV === 'development' ? "http://192.168.1.3:4000/" : "https://admin.btip.be/"

const config = {
    API_URL: BASIC_URL,
    IMAGE_URL: BASIC_URL + "images/",
};

export default config;

// 84.16.66.164

// hera.ns.cloudflare.com
// lou.ns.cloudflare.com
// ns3.twshosting.ca
